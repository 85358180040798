import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

export const Headline = styled.h1`
  color: ${props => props.theme.colors.textSecondary};
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.colors.textPrimary};
  margin-top: 20px;
`;

const activeClassName = 'nav-item-active';
export const Anchor = styled(props => <Link {...props} />).attrs({
  activeClassName,
})`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};

  &.${activeClassName} {
    font-weight: bold;
  }

  &::after {
    display: block;
    content: "${props => props.children}";
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const Markdown = styled.div`
  & > h1 {
    margin: 20px 0;
    color: ${props => props.theme.colors.textSecondary};
  }

  & > h2 {
    margin: 10px 0;
    font-size: 1.2em;
    font-weight: 500;
    color: ${props => props.theme.colors.textPrimary};
  }

  & ul {
    margin-top: 12px;
  }

  & li {
    color: ${props => props.theme.colors.textPrimary};
    list-style: initial;
    font-weight: lighter;
    margin-left: 20px;
  }

  & > p {
    color: ${props => props.theme.colors.textPrimary};
    margin-top: 12px;
    font-weight: lighter;
  }
`;
