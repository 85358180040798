import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import { Anchor } from './';

const Container = styled.footer`
  flex-shrink: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.5vw 5vw;
`;

const Contact = styled.div`
  font-size: 0.9em;
  margin: 20px 0;
`;

const Hours = styled.div`
  font-size: 0.9em;
  margin: 20px 0;
`;

const SectionHeader = styled.h4`
  color: #333;
`;

const Section = styled.div`
  color: #333;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.2em;
`;

const ContactLink = styled.a`
  color: ${props => props.theme.colors.primary};
`;

const Footer = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        file(relativePath: { eq: "footer.md" }) {
          childMarkdownRemark {
            frontmatter {
              title
            }
            html
          }
        }
      }
    `}
    render={data => (
      <Container>
        <Hours>
          <SectionHeader>
            {data.file.childMarkdownRemark.frontmatter.title}
          </SectionHeader>
          <Section
            dangerouslySetInnerHTML={{
              __html: data.file.childMarkdownRemark.html,
            }}
          />
        </Hours>
        <Contact>
          <Section>nevin hair – Friseur Meisterbetrieb</Section>
          <Section>Waldstraße 18, 63303 Dreieich</Section>
          <Section>
            Email:{' '}
            <ContactLink href="mailto:info@nevin-hair.de">
              info@nevin-hair.de
            </ContactLink>
          </Section>
          <Section>
            Telefon:{' '}
            <ContactLink href="tel:004961032706464">06103 2706464</ContactLink>
          </Section>
        </Contact>
        <Links>
          <Anchor to="/impressum/">Impressum</Anchor>
          <Anchor to="/datenschutz/">Datenschutz</Anchor>
        </Links>
      </Container>
    )}
  />
);

export default Footer;
