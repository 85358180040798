import React from 'react';
import styled from 'styled-components';

import { media, sizes } from '../../utils/style';
import Media from 'react-media';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';

const Container = styled.div`
  & > .carousel {
    position: relative;
  }
  z-index: 2;
  padding: 10vw 2.5vw 2.5vw 2.5vw;
  margin: -6.5vw 5vw 3vw 5vw;
  background: ${props => props.theme.colors.backgroundSecondary};
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  ${media.phone`
    margin: 0;
    padding: 2vw;
    border-radius: 5px 5px 0 0;
  `};
`;

const ButtonLeft = styled(ButtonBack)`
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px;
  transition: background-color 0.3s;
  transform: translateY(-50%);
  border: 0 none;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.5);
  left: -10px;
`;

const ButtonRight = styled(ButtonNext)`
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px;
  transition: background-color 0.3s;
  transform: translateY(-50%);
  border: 0 none;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.5);
  right: -10px;
`;

const ButtonImg = styled.img`
  width: 100%;
`;

const Carousel = ({ Img, images, firstIndex = 0, isPlaying = true }) => (
  <Media defaultMatches={false} query={`(max-width: ${sizes.phone / 16}em)`}>
    {match => (
      <Container>
        <CarouselProvider
          visibleSlides={match ? 2 : 3}
          naturalSlideWidth={3}
          naturalSlideHeight={4}
          totalSlides={images.length}
          isPlaying={isPlaying}
          step={2}
          dragEnabled={false}
        >
          <Slider>
            {images.map((image, i) => (
              <Slide key={i} index={i}>
                <Img key={i} {...image} />
              </Slide>
            ))}
          </Slider>
          <ButtonLeft>
            <ButtonImg alt="back" src={chevronLeft} />
          </ButtonLeft>
          <ButtonRight>
            <ButtonImg alt="next" src={chevronRight} />
          </ButtonRight>
        </CarouselProvider>
      </Container>
    )}
  </Media>
);

export default Carousel;
