import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    backgroundPrimary: '#f3eee6',
    backgroundSecondary: '#b8ab94',
    primary: '#732322',
    secondary: '#e7dece',
    textPrimary: '#732322',
    textSecondary: '#b8ab94',
  },
};

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  background: ${theme.colors.backgroundPrimary};
}
html,
body {
    overflow-x: hidden;
} 
* {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  list-style: none;
}
`;

export default props => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <GlobalStyle />
      {props.children}
    </Fragment>
  </ThemeProvider>
);
