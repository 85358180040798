import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { media } from '../utils/style';
import logo from './logo.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1vw;
  ${media.phone`
  margin: 7.5vw 0;
    width: 100%;
  `};
`;

const Image = styled.img`
  height: 50px;
`;

const Header = props => (
  <Container>
    <Link to="/">
      <Image src={logo} alt="logo" />
    </Link>
  </Container>
);

export default Header;
