import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { media } from '../utils/style';
import Theme from './Theme';
import Logo from './Logo';
import Navigation from './Navigation';
import Menu from './Menu';
import Carousel from './Carousel';
import Footer from './Footer';

// const rotate = (arr, num) => [...arr.slice(num), ...arr.slice(0, num)];

const Container = styled.main`
  background-color: ${props => props.theme.colors.backgroundPrimary};
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Page = styled.article`
  background: #e7dece;
  margin: -10vw 1vw 0 1vw;
  padding: 10vw 14vw 5vw 20vw;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  flex: 1;
  ${media.phone`
    margin: 0;
    padding: 5vw;
    border-radius: 0 0 5px 5px;
  `};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 7;
  flex: 0 0 auto;
  margin: 2vw 1vw 0 1vw;
  background: ${props => props.theme.colors.secondary};
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  z-index: 7;
  flex: 0 0 auto;
  margin: 2.5vw 1vw 0 1vw;
  ${media.phone`
    margin: 0;
    background: initial;
    border-radius: initial;
    box-shadow: initial;
  `};
`;

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }

        angebote: file(relativePath: { eq: "angebote.md" }) {
          childMarkdownRemark {
            frontmatter {
              showlink
            }
          }
        }

        images: file(relativePath: { eq: "carousel.md" }) {
          childMarkdownRemark {
            frontmatter {
              bilder {
                alt
                bild {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
            { name: 'theme-color', content: '#f3eee6' },
          ]}
        >
          <html lang="de" />
        </Helmet>
        <Theme>
          <Fragment>
            <Menu
              showAngebote={
                data.angebote.childMarkdownRemark.frontmatter.showlink
              }
            />
            <Container id="main">
              <Header>
                <Logo />
                <Navigation
                  showAngebote={
                    data.angebote.childMarkdownRemark.frontmatter.showlink
                  }
                />
              </Header>
              <Carousel
                images={data.images.childMarkdownRemark.frontmatter.bilder.map(
                  ({ bild, alt }) => ({
                    fluid: bild.childImageSharp.fluid,
                    alt,
                  })
                )}
                Img={Img}
              />
              <Page>{children}</Page>
              <Footer />
            </Container>
          </Fragment>
        </Theme>
      </Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
