import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Menu from 'react-burger-menu/lib/menus/reveal';

import { media } from '../utils/style';

const pages = [
  { label: 'Über uns', slug: '/' },
  { label: 'Hairstyling', slug: '/hairstyling/' },
  { label: 'Preise', slug: '/preise/' },
  { label: 'Angebote', slug: '/angebote/' },
];

const activeClassName = 'nav-item-active';
const StyledLink = styled(Link).attrs({
  activeClassName,
})`
  font-size: 1.4em;
  text-decoration: none;
  color: ${props => props.theme.colors.textSecondary};

  &.${activeClassName} {
    font-weight: bold;
  color: ${props => props.theme.colors.textPrimary};
  }

  &::after {
    display: block;
    content: "${props => props.children}";
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

const Wrapper = styled.div`
  display: none;

  ${media.phone`
    display: initial;
  `};

  & .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 15px;
    top: 10vw;
  }

  & .bm-burger-bars {
    background: ${props => props.theme.colors.primary};
  }

  & .bm-cross {
    background: ${props => props.theme.colors.primary};
  }

  & .bm-menu {
    background: white;
  }

  & .bm-item-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    background: ${props => props.theme.colors.background};
  }

  & .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

class MobileMenu extends Component {
  state = {
    isOpen: false,
  };
  handleStateChange = state => this.setState({ isOpen: state.isOpen });
  closeMenu = () => this.setState({ isOpen: false });

  render() {
    const { showAngebote } = this.props;
    console.log(this.props);
    return (
      <Wrapper>
        <Menu
          isOpen={this.state.isOpen}
          onStateChange={state => this.handleStateChange(state)}
          width="70vw"
          pageWrapId="main"
        >
          {pages.map(
            ({ slug, label }) =>
              slug !== '/angebote/' || showAngebote ? (
                <StyledLink
                  key={slug}
                  onClick={this.closeMenu}
                  activeClassName={activeClassName}
                  to={slug}
                >
                  {label}
                </StyledLink>
              ) : null
          )}
        </Menu>
      </Wrapper>
    );
  }
}

export default MobileMenu;
