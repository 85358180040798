import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { media } from '../utils/style';

const pages = [
  { label: 'Über uns', slug: '/' },
  { label: 'Hairstyling', slug: '/hairstyling/' },
  { label: 'Preise', slug: '/preise/' },
  { label: 'Angebote', slug: '/angebote/' },
];

const Container = styled.nav`
  position: relative;
  flex: 1;
  ${media.phone`
    display:none;
  `};
`;

const Ul = styled.ul`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  height: 75px;
  align-items: center;
  ${media.tablet`
    height: 50px
  `};
`;

const Li = styled.li`
  list-style-type: none;
  margin: 0;
`;

const activeClassName = 'nav-item-active';
const StyledLink = styled(Link).attrs({
  activeClassName,
})`
  font-size: 1.5em;
  text-decoration: none;
  color: ${props => props.theme.colors.textSecondary};

  &.${activeClassName} {
    color: ${props => props.theme.colors.textPrimary};
    font-weight: 600;
  }

  &::after {
    display: block;
    content: "${props => props.children}";
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ${media.tablet`
    font-size: 1em;
  `};
`;

const Navigation = ({ showAngebote }) => (
  <Container>
    <Ul>
      {pages.map(
        ({ slug, label }) =>
          slug !== '/angebote/' || showAngebote ? (
            <Li key={slug}>
              <StyledLink activeClassName={activeClassName} to={slug}>
                {label}
              </StyledLink>
            </Li>
          ) : null
      )}
    </Ul>
  </Container>
);

export default Navigation;
